
.tool-container {

  height: 35vh;
  background-color: azure;

  .cropper-area {
    width: 30vw;
    height: 20vh;
    max-height: 98%;
  }
  
  .preview-area {
    width:10vw;
    margin-top: 0.5rem;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .crop-placeholder {
    width: 100%;
    height: 200px;
    background: #ccc;
  }
  
  .cropped-image {
    border: 2px dotted #f5222d;
    img {
        width: 10vw;
        max-width: 100%;
    }
  }
  
  .button-area {
    margin-top: 0.2rem;
    margin-left: 1rem;
    width: 30rem;
    button {
        line-height: 0.1rem;
        height: 1.8rem;
        margin: 0.2rem 0.2rem;
    }
  }

  .saved-image {
    /*border: 1px solid #eee;*/
    padding: 0.5rem;
    height: 10rem;
    img {
        width: 8rem;
        height: 6rem;
    }
    span {
        color: #1e89f6;
    }
  }
  
  .custombtn {
        line-height: 0.1rem;
        height: 1.8rem;
        margin: 0.2rem 0.2rem;
        background: #109114;
        float: right;
  }

}
